import { chunk } from 'lodash';
import { InitAppForPageFn } from '@wix/yoshi-flow-editor';
import {
  BatchFetch,
  BatchFetchData,
  initSharedAppStateStore,
} from './shared-app-state/shared-app-state';
import { listAttributeAveragesByEntity } from '@wix/ambassador-ratings-v1-rating/http';
import { parseInstance } from '~commons/instance/parse-instance';
import { EntityAverage } from '@wix/ambassador-ratings-v1-rating/types';
import { buildDemoContentForEntity } from '~commons/demo-content';

const BATCH_FETCH_LIMIT = 100;

export const initAppForPage: InitAppForPageFn = async (
  _initAppParam,
  _platformUtilitiesApi,
  _scopedGlobalSdkApis,
  _platformServices,
  flowAPI,
) => {
  const httpClient = flowAPI.httpClient;

  const fetchRatings = async (
    namespace: string,
    resourceIds: string[],
  ): Promise<EntityAverage[]> => {
    const responses = await Promise.all(
      chunk(resourceIds, BATCH_FETCH_LIMIT).map((resourceIdChunk) =>
        httpClient.request(
          listAttributeAveragesByEntity({
            group: namespace,
            entityIds: resourceIdChunk,
            namespace: 'reviews',
            attributeId: 'overall',
            attributeName: 'overall',
          }),
        ),
      ),
    );
    return responses.reduce(
      (acc, response) => [...acc, ...(response.data.entityAverages ?? [])],
      [] as EntityAverage[],
    );
  };

  const batchFetch: BatchFetch = ({ resourceIds, namespace }) => {
    const { siteIsTemplate } = parseInstance(_initAppParam.instance);
    return (
      siteIsTemplate || flowAPI.environment.isEditor || flowAPI.environment.isPreview
        ? (() => {
            const mockReviews = resourceIds.map(buildDemoContentForEntity);
            return Promise.resolve(
              mockReviews.map<EntityAverage>(({ entityId, average, total }) => ({
                entityId,
                total,
                average,
              })),
            );
          })()
        : fetchRatings(namespace, resourceIds)
    ).then(
      (entityAverages) => {
        const data =
          entityAverages.reduce<BatchFetchData>((acc, { entityId, total, average }) => {
            if (!entityId) {
              return acc;
            }
            acc[entityId] = { totalReviews: total ?? 0, overall: average ?? 0 };
            return acc;
          }, {}) ?? {};
        return { type: 'OK', data };
      },
      () => ({ type: 'ERROR' }),
    );
  };
  const sharedAppStateStore = initSharedAppStateStore({ batchFetch });
  if (process.env.NODE_ENV === 'test') {
    // @ts-expect-error
    window.sharedAppStateStore = sharedAppStateStore;
  }
  return {
    sharedAppStateStore,
  };
};
